import React from "react";
import {  Link } from "gatsby"

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";

import { TiMessageTyping, TiHeartOutline } from "react-icons/ti";

import particle1 from "common/src/assets/images/app/particle/16.png";
import particle2 from "common/src/assets/images/app/particle/09.png";




import NewsSectionWrapper from "./newsSection.style";

const News = ({data}) => {
  return (
    <NewsSectionWrapper>
      <img className="section__particle one" src={particle1} alt="img" />
      <img className="section__particle two" src={particle2} alt="img" />
      <Container>
        <SectionTitle className="section-title" UniWidth="52%">
          <h4 style={{color: "var(--textNormal)"}}>Mi blog</h4>
          <h2 style={{color: "var(--textBanner)"}}>
            <span>Artículos </span> mas recientes.
          </h2>
        </SectionTitle>
        <Row>
        {data.allWordpressPost.edges.slice(0, 3).map(post => (
          <Col xs={12} sm={6} lg={4} key={post.node.wordpress_id}>
            <div className="news-wrap">
              <div className="thumb-block">
                <Link to={"/"+post.node.slug}>
                  <img width="600" src={post.node.featured_media.source_url} alt="img" />
                </Link>
              </div>
              <div className="post-meta-block">
                Publicado
                <span className="divider">/</span>
                <span>{post.node.date}</span>
              </div>
              <Link to={"/"+post.node.slug}>
                <h2 style={{color: "var(--textBanner)"}}
                  dangerouslySetInnerHTML={{ __html: post.node.title }}
                >
                </h2>
              </Link>
              <p className="post-entry"
              style={{color: "var(--textNormal)"}}
                  dangerouslySetInnerHTML={{ __html: post.node.excerpt }}

              >
                
              </p>
            </div>
          </Col>
        ))}
        </Row>
      </Container>
    </NewsSectionWrapper>
  );
};

export default News;

