import React from "react";
import { Link } from "gatsby";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";
import Button from "common/src/reusecore/Button";
import VintageBox from "common/src/reusecore/VintageBox";

import integrationImage1 from "common/src/assets/images/app/integrations/integration-1.png";
import integrationImage2 from "common/src/assets/images/app/integrations/integration-2.png";
import integrationImage3 from "common/src/assets/images/app/integrations/integration-3.png";
import integrationImage4 from "common/src/assets/images/app/integrations/integration-4.png";
import Icon from "common/src/assets/images/app/integrations/arrow.png";

import IntegrationItemWrapper from "./integrationSection.style";
import { FaGithub } from "react-icons/fa";


const Integrations = () => {
  return (
    <IntegrationItemWrapper>
      <Container>
        <Row Vcenter={true}>
          <Col xs={12} sm={6}>
            <div className="integration__block__wrap">
              <div className="integration__block">
                <div className="integration__block__inner">
                  <img src={integrationImage1} alt="appion integration" />
                  <h3 style={{color: "var(--textBanner)"}}>REACT</h3>
                  <p style={{color: "var(--textNormal)"}}>SPA</p>
                  <a href="https://vwfms.com/camiones-man/" target="_blank" rel="noopener noreferrer">
                    <img src={Icon} alt="appion app" />
                  </a>
                </div>
                <div className="integration__block__inner">
                  <img src={integrationImage2} alt="appion integration" />
                  <h3 style={{color: "var(--textBanner)"}}>JavaScript</h3>
                  <p style={{color: "var(--textNormal)"}}>Front End</p>
                  <a href="https://www.ibroken.com.mx/wp-content/themes/Impreza/cotizador/cotizador-de-precios.html" target="_blank" rel="noopener noreferrer">
                    <img src={Icon} alt="appion app" />
                  </a>
                </div>
              </div>
              <div className="integration__block right">
                <div className="integration__block__inner">
                  <img src={integrationImage3} alt="appion integration" />
                  <h3 style={{color: "var(--textBanner)"}}>Wordpress</h3>
                  <p style={{color: "var(--textNormal)"}}>Control Managment System</p>
                  <a href="https://mindfulnessdemexico.com/" target="_blank" rel="noopener noreferrer">
                    <img src={Icon} alt="appion app" />
                  </a>
                </div>
                <div className="integration__block__inner">
                  <img src={integrationImage4} alt="appion integration" />
                  <h3 style={{color: "var(--textBanner)"}}>PHP</h3>
                  <p style={{color: "var(--textNormal)"}}>BackEnd</p>
                  
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} className="integration-content-wrap">
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              <h4 style={{color: "var(--textNormal)"}}>Lo que más uso</h4>

              <h2 style={{color: "var(--textBanner)"}}>
                Para <span>programar</span>.
              </h2>
            </SectionTitle>
            <p style={{color: "var(--textNormal)"}} className="integration-text">
            Estas son las herramientas en las que me he especializado como desarrolladora web.


            </p>
            <VintageBox
              right={true}
              vintageTwo={true}
              position="relative"
            >
              <a target="_blank" href="https://github.com/AlmaJaneiro" rel="noopener noreferrer">
                <Button className="integration-btn"><FaGithub className="icon-left" /> Github</Button>
              </a>
            </VintageBox>
          </Col>
        </Row>
      </Container>
    </IntegrationItemWrapper>
  );
};

export default Integrations;
