import React, {Fragment} from "react";
import { Container, Row, Col } from "common/src/reusecore/Layout";

const Imagen = ({img_port, nombre}) => {
    return(
        <Fragment>
            <Col xs={12} sm={6}>
              <img
                className="about-thumb"
                src={img_port}
                alt={nombre}
              />
            </Col>
        </Fragment>
    )
}

export default Imagen;