import React from "react";

import { FaDesktop, FaPhone, FaInstagram, FaLinkedinIn ,FaFacebookF, FaTwitter} from "react-icons/fa";

let  icono = <FaTwitter />
const Social = ({social_icon, link}) => {
    switch (social_icon){
        case "twitter":
            icono = <FaTwitter />
            break;
        case "facebook":
            icono = <FaFacebookF />
            break;
        case "instagram":
            icono = <FaInstagram />
            break;
        case "linkedin":
            icono = <FaLinkedinIn />
            break;
        case "linkedin":
            icono = <FaLinkedinIn />
            break;
        case "web":
            icono = <FaDesktop />
            break;
        default:
            icono = <FaTwitter />
            break;
    }
    return(
        <li>
          <a style={{color: "var(--textBanner)"}} target="_blank" rel="noopener noreferrer" href={link}>
            {icono}
          </a>
        </li>
    )
}

export default Social;