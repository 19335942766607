import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Navigation from "common/src/sections-gatsby/Navigation"
import Banner from "common/src/sections-gatsby/Banner-modern"
import AboutClassic from "common/src/sections-gatsby/About-classic"
import Features from "common/src/sections-gatsby/Features"
import Integrations from "common/src/sections-gatsby/Integrations"
import News from "common/src/sections-gatsby/News"
import Contact from "common/src/sections-gatsby/Contact"
import Footer from "common/src/sections-gatsby/Footer-classic"
import About from "common/src/sections-gatsby/About"


import { GlobalStyle } from "common/src/sections-gatsby/app.style"
import theme from "common/src/theme/modern/themeStyles"

import ReactGA from 'react-ga';
ReactGA.initialize('UA-64452838-7');


const IndexPage = ({data}) => {
  
  ReactGA.pageview("/")
  const imgBlog ="https://blog.almasuarez.com/wp-content/uploads/2020/04/dubai-alma.jpg"
  const desc = "Software Engineer, Traveler and Food Lover 🌮"
  return(
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout>
        <SEO 
            title="Alma Fernanda, Software Engineer " 
            image={imgBlog}
            description={desc}
        />
        <Navigation />
        <Banner />
        <AboutClassic />
        <Features />
        <About />
        <Integrations />
        <News data={data} />
        <Contact />
        <Footer data={data} />
      </Layout>
    </ThemeProvider>
  )
}

export default IndexPage


export const query = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          wordpress_id
          title
          excerpt
          slug
          date(formatString: "YYYY-MM-DD")
          categories {
            name
            slug
          }
          featured_media {
            source_url
          }
        }
      }
    }
  }
`