import React from "react";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";

import Icon1 from "common/src/assets/images/app/features/01.svg";
import Icon2 from "common/src/assets/images/app/features/02.svg";
import Icon3 from "common/src/assets/images/app/features/03.svg";
import Icon4 from "common/src/assets/images/app/features/04.svg";
import Icon5 from "common/src/assets/images/app/features/05.svg";
import Icon6 from "common/src/assets/images/app/features/06.svg";

import FeturesSectionWrapper from "./fetures.style";

const Features = () => {
  return (
    <FeturesSectionWrapper id="features">
      <Container fullWidthSM>
        <SectionTitle UniWidth="65%">
          <h4 style={{color: "var(--textNormal)"}}>Pero si escribes código,</h4>
          <h2 style={{color: "var(--textBanner)"}}>
          ¿Cuál es <span>tu giro?</span>
          </h2>
        </SectionTitle>
        <Row>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v1">
              <div className="fetures-icon-block">
                <img src={Icon1} alt="img" />
              </div>
              <h3 style={{color: "var(--textBanner)"}}>Desarrollo web</h3>
              <p style={{color: "var(--textNormal)"}}>
                Quieres empezar con la <b>digitalización</b> de tu empresa o startup y no sabes con quien acudir,
                tranquilo, estás en el <b>lugar adecuado.</b> De acuerdo a tus ideas puedo crearte una landing page, formularios de contacto, mailings masivos, 
                integración de un CRM, gestionar tu sitio web, o si prefieres un CMS o LMS, puedo hacerlo.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v2">
              <div className="fetures-icon-block">
                <img src={Icon2} alt="img" />
              </div>
              <h3 style={{color: "var(--textBanner)"}}>E-commerce</h3>
              <p style={{color: "var(--textNormal)"}}>
                Si ya cuentas con una <b>tienda física</b> o quieres empezar a <b>vender en linea</b>, tienes que crear un <b>E-commerce</b>. Si quieres integrar ese E-commerce 
                con <b>Instagram Shop</b> también puede ser realidad. El E-commerce está compuesta de una serie de pasos que pueden ser estresantes al inicio, pero tendrán muchos beneficios,
                como tener un segundo canal de ingresos en tu economía. El E-commerce que suelo hacer, también incluye una aplicación móvil en <b>IOS y Android</b> para que puedas revisar de una manera
                más fácil y rápida tus pedidos.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v1">
              <div className="fetures-icon-block">
                <img src={Icon3} alt="img" />
              </div>
              <h3 style={{color: "var(--textBanner)"}}>Aplicaciones móviles</h3>
              <p style={{color: "var(--textNormal)"}}>
              Quieres una aplicación móvil en <b>Android - IOS</b> o en <i>ambas plataformas</i>,
                no te preocupes, estoy aquí para estructurar tu ideas y poner en marcha el desarrollo de tu aplicación
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v2">
              <div className="fetures-icon-block">
                <img src={Icon4} alt="img" />
              </div>
              <h3 style={{color: "var(--textBanner)"}}>Wordpress</h3>
              <p style={{color: "var(--textNormal)"}}>
                Deseas poder modificar el texto de tu sitio web, crear un E-commerce, roles de usuarios, creación de páginas, tu propio blog, posicionamiento web,
                y tener el control por ti mismo, entonces, necesitas un Content Managment System como Wordpress que te permite crear esto y muchas cosas más.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v1">
              <div className="fetures-icon-block">
                <img src={Icon5} alt="img" />
              </div>
              <h3 style={{color: "var(--textBanner)"}}>Bulk mail</h3>
              <p style={{color: "var(--textNormal)"}}>
                No descuides a tus clientes y empieza con una campaña de <b>email marketing</b>,
                personaliza los diseños con los nombres de tus clientes, manda emojis, diseño responsivo, manda recordatorios, 
                felicita a tus clientes en sus cumpleaños, mantente presente.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v2">
              <div className="fetures-icon-block">
                <img src={Icon6} alt="img" />
              </div>
              <h3 style={{color: "var(--textBanner)"}}>Desarrollo personalizado</h3>
              <p style={{color: "var(--textNormal)"}}>
                  Quieres un desarrollo personalizado e integrarlo con otras aplicaciones, puedo asistirte con
                  el desarrollo de una API que conecte tu sitio web con la aplicación que buscas.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </FeturesSectionWrapper>
  );
};

export default Features;
