import React, {Fragment} from "react";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";
import Button from "common/src/reusecore/Button";
import VintageBox from "common/src/reusecore/VintageBox";

import imgAbout from "common/src/assets/images/app/fernando-caballero.png";
import imgABoletos from "common/src/assets/images/app/aboletos.png";
import particle1 from "common/src/assets/images/app/particle/05.png";
import particle2 from "common/src/assets/images/app/particle/06.png";

import AboutSectionWrapper from "./about.style";
import Imagen from "./Imagen.js";
import Portafolio from "./Portafolio.js";

import { FaDesktop, FaPhone, FaInstagram, FaLinkedinIn ,FaFacebookF, FaTwitter} from "react-icons/fa";

let socialJson =  {
  "social" : [
    {
      "social": "twitter",
      "link": "https://twitter.com/ShalomDayanna"
    },
    {
      "social": "instagram",
      "link": "https://www.instagram.com/shalomdayanna/"
    },
    {
      "social": "linkedin",
      "link": "https://www.linkedin.com/in/shalom-dayanna-62b95398/"
    },
    {
      "social": "web",
      "link": "https://shalomdayanna.com/"
    },
  ]
}
const About = () => {
  return (
    <AboutSectionWrapper id="portafolio">
      <img className="section__particle one" src={particle1} alt="img" />
      <img className="section__particle two" src={particle2} alt="img" />
      <Container>
        <Row>
          <section className="seccion_portafolio">
            <Imagen img_port={imgAbout} nombre={"Fernando Caballero - Wedding Photographer"}/>
            <Portafolio 
              titulo={"Fernando"} 
              subtitulo={"Caballero"} 
              proyecto={"Sitio web de fotografía"} 
              design={"Shalom Dayanna"} 
              tools={"Wordpress"} 
              link_web={"https://fernandocaballero.com.mx/"}
              social_links = {socialJson.social}
            />
          </section>
          <hr />
          <section className="seccion_portafolio">
            <Portafolio 
              titulo={"Aboletos"} 
              subtitulo={"E-commerce"} 
              proyecto={"Sitio web de boletos"} 
              design={"Shalom Dayanna"} 
              tools={"Wordpress, Woocommerce"} 
              link_web={"https://aboletos.mx/"}
              social_links = {socialJson.social}
            />
            <Imagen img_port={imgABoletos} nombre={"Aboletos - Woocommerce Site"}/>
        </section>
        </Row>
      </Container>
    </AboutSectionWrapper>
  );
};

export default About;
