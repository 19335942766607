import React, {Fragment} from 'react';
import SectionTitle from "common/src/reusecore/SectionTitle";
import VintageBox from "common/src/reusecore/VintageBox";
import Button from "common/src/reusecore/Button";
import { FaDesktop} from "react-icons/fa";
import { Container, Row, Col } from "common/src/reusecore/Layout";
import Social from "./Social.js";


const Portafolio = ({titulo, subtitulo, proyecto, design, tools, link_web,social_links}) => {
    return (
        <Fragment>
            <Col xs={12} sm={6} className="about-content-wrap">
              <SectionTitle
                className="section-title"
                leftAlign={true}
                UniWidth="100%"
              >
                <h4 style={{color: "var(--textNormal)"}}>Portafolio</h4>
                <h2 style={{color: "var(--textBanner)"}}>
                  <span>{titulo}</span> {subtitulo}
                </h2>
              </SectionTitle>
              <p className="about-text text-one" style={{color: "var(--textNormal)"}}>
                {proyecto}
              </p>
              <p className="about-text text-one" style={{color: "var(--textNormal)"}}>
                Desarrollado por: <b>Alma</b> <br />
              </p>
              <ul className="social">
                <li style={{color: "var(--textNormal)"}}>
                    Diseño:  <b>{design}</b> 
                </li>
                {
                    social_links.map((item, num) => {
                        return(
                            <Social key={num} social_icon={item.social} link={item.link} />
                        )
                    })
                }
              </ul>
              <p className="about-text text-one"  style={{color: "var(--textBanner)", paddingTop: "30px"}}>
                <b>{tools}</b>
              </p>
              <VintageBox
                right={true}
                vintageTwo={true}
                position="relative"
              >
                <a href={link_web} target="_blank" rel="noopener noreferrer">
                  <Button> <FaDesktop className="icon-left" />Sitio web</Button>
                </a>
              </VintageBox>
            </Col>
        </Fragment>
    );
};

export default Portafolio;