import React from "react";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";
import Button from "common/src/reusecore/Button";
import VintageBox from "common/src/reusecore/VintageBox";
import ParticleComponent from "common/src/reusecore/Particle";
import Typewriter from 'typewriter-effect';


import { FaLinkedin } from "react-icons/fa";

import BannerSectionWrapper from "./banner.style";
import imgHero from "common/src/assets/images/app/hero/mobile-screen.png";


const Edad = (birthday) => {
  var today = new Date();
  var birthDate = new Date(birthday);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
  }
  return age;
}

const BannerModern = () => {
  return (
    <BannerSectionWrapper>
      <ParticleComponent />
      <Container fullWidthSM>
        <Row Vcenter={true}>
          <Col sm={7} md={6}>
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              <h1 style={{color: "var(--textBanner)"}}>
                Alma Fernanda ✌🏼
                  <span>
                    <Typewriter
                      options={{
                        strings: ['Web Developer 💻', 'Traveler 🚀','Food Lover 🌮',"In love with asian culture 🍜","Chicken lover 🍗", "Front End 👩🏽‍💻", "Javascript 🧨" ,'Wordpress ❤️', "Woocommerce ❤️", "React ❤️", "Gatsby ❤️"],
                        autoStart: true,
                        loop: true,
                        delay: 100
                      }}
                    />
                </span>
              </h1>
            </SectionTitle>
            <p style={{color: "var(--textNormal)"}}>
                    <b>#{Edad("11/08/1993")}</b> Programadora de profesión, viajera de vocación
            </p>
            <VintageBox right={true} vintageOne={true}>
              <a target="_blank" href="https://www.linkedin.com/in/alma-fernanda-suarez-777a32b3/" rel="noopener noreferrer">
                <Button className="banner-btn two" >
                  <FaLinkedin className="icon-left" /> Linkedin
                </Button>
              </a>
            </VintageBox>
          </Col>
          <Col sm={5} md={6}>
            <img
              src={imgHero}
              className="hero-img"
              alt="prime-app hero image"
            />
          </Col>
        </Row>
      </Container>
    </BannerSectionWrapper>
  );
};

export default BannerModern;
