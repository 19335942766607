import React, { Component } from 'react';

import Recaptcha from "react-recaptcha";

import { Container } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";
import Button from "common/src/reusecore/Button";

import ContactWrapper from "./contact.style";

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// create a variable to store the component instance
let recaptchaInstance;

const ContactoSchema = Yup.object().shape({
  nombre: Yup.string()
    .min(7, '¡Es muy pequeño!')
    .max(50, '¡Es muy largo!')
    .required('Requerido'),
  telefono: Yup.string()
    .required('Requerido')
    .matches(/^[0-9]+$/, "Solo números")
    .min(7, 'Es muy corto')
    .max(10, 'Es muy largo'),
  email: Yup.string()
    .email('¡Correo inválido!')
    .required('Requerido'),
  mensaje: Yup.string()
    .min(10, '¡Es muy pequeño!')
    .max(200, '¡Es muy largo!')
    .required('Requerido'),
     
});



// create a reset function
const resetRecaptcha = () => {
  recaptchaInstance.reset();
}
class Contact extends Component {
  
  constructor(props) {
      super(props);

      this.state = {
        recaptcha: "",
        boton: false
      }
      

  }
  componentDidMount() {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
  render() {

    const miFunction = (response) =>{
      if(response){
        this.setState({
           recaptcha: response
        })
      }
    }
    
    const timer = (men) => setTimeout(() => {
      men.innerHTML = ""
      }, 2500);


    const EnviarForm =  (valores) => {

      this.setState({
        boton: true
      })
      let men = document.getElementById("Resultado")
        return fetch('https://blog.almasuarez.com/wp-content/themes/alma-es-chida/functions/ajax/alma-contacto.php', {
          method: 'POST',
          header :{
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              nombre  : valores.nombre,
              email   : valores.email,
              telefono: valores.telefono,
              mensaje : valores.mensaje,
              recaptcha: this.state.recaptcha
          })
        })
        .then((response) => response.json())
        .then((responseJson) => {
          let data = responseJson;
          this.setState({
            recaptcha: "",
            boton: false
          })
          men.innerHTML = data.mensaje
          timer(men)

          })
        .catch((error) => {
            console.log(error);
        });

    }
    return (
      <ContactWrapper id="contact">
        <SectionTitle className="section-title" UniWidth="55%">
          <h4 style={{color: "var(--textNormal)"}}>Contactáme</h4>
          <h2 style={{color: "var(--textBanner)"}}>
            <span>No seas tímido </span> mándame un mensaje.
          </h2>
        </SectionTitle>
        <Container>
          <Formik
            initialValues={{
              nombre: '',
              telefono: '',
              email: '',
              mensaje: '',
            }}
            validationSchema={ContactoSchema}
            onSubmit={(values, {resetForm}) => {

              let men = document.getElementById("Resultado")
              if(this.state.recaptcha == ""){

                resetRecaptcha();
                men.innerHTML = "Captcha requerido"
                timer(men)

              }
              else{
                resetRecaptcha();
                EnviarForm(values);
                resetForm();

              }
            }}
          >
             {({ errors, touched }) => (
              <Form name="contactform" method="post" action="#" style={{background: "var(--FormContacto)"}}>
                <div style={{width: "100%"}}><span style={{color: "var(--textBanner)", fontWeight: "bold"}} id="Resultado"></span></div>
                <div className="cien">
                    <Field name="nombre" placeholder="*Escribe aquí tu nombre" />
                    {errors.nombre && touched.nombre ? (
                        <div className="error" >{errors.nombre}</div>
                      ) : null}
                </div>
                <div className="cien">
                    <Field name="telefono" placeholder="*Escribe tu teléfono" />
                    {errors.telefono && touched.telefono ? (
                        <div className="error" >{errors.telefono}</div>
                      ) : null}
                </div>
                <div className="cien">
                    <Field name="email" type="email" placeholder="*Escribe tu correo" />
                    {errors.email && touched.email ? (
                        <div className="error" >{errors.email}</div>
                      ) : null}
                </div>
                <div className="cien">
                    <Field name="mensaje" placeholder="*Escribe tu mensaje" />
                    {errors.mensaje && touched.mensaje ? (
                        <div className="error" >{errors.mensaje}</div>
                      ) : null}
                </div>
                <div className="text-center" style={{width: "100%", margin: "0 auto"}}>
                    <Recaptcha
                      ref={e => recaptchaInstance = e}
                      sitekey="6Lf_M_QUAAAAAEVE3EQr_Xbo8gNNNZALGxBu8lQh"
                      render="explicit"
                      verifyCallback={ (response) => {

                        miFunction(response)
                        
                        
                      }}
                      onloadCallback={() => { console.log("Cargado correctamente"); }}
                    />
                    {errors.recaptcha 
                      && touched.recaptcha && (
                      <p className="error" >{errors.recaptcha}</p>
                    )}
                </div>
                <div className="btn-send">
                  <Button  
                    disabled={this.state.boton}
                  type="submit">Enviar mensaje</Button>
                </div>
              </Form>
            )}  
          </Formik>
        </Container>
      </ContactWrapper>
    );
  }
}

export default Contact;