import React from "react";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";

import aboutIcon1 from "common/src/assets/images/classic/about/icon-4.svg";
import aboutIcon2 from "common/src/assets/images/classic/about/icon-5.svg";
import aboutIcon3 from "common/src/assets/images/classic/about/icon-6.svg";

import AboutSectionWrapper from "./about.style";

const AboutClassic = () => {
  return (
    <AboutSectionWrapper id="about">
      <Container>
        <SectionTitle UniWidth="65%">
          <h2 style={{color: "var(--textBanner)"}}>
           Entonces, ¿quién es <span>Alma Fernanda?</span> 
          </h2>
        </SectionTitle>
        <Row>
          <Col xs={12} sm={6} lg={4}>
            <div className="single-item">
              <div className="item-head">
                <div className="item-icon">
                  <img src={aboutIcon1} alt="appion app about icon" />
                </div>
                <h3 style={{color: "var(--textBanner)"}}>Viajes</h3>
              </div>

              <p className="justificado" style={{color: "var(--textNormal)"}}>
              Hola, mi nombre es <b>Alma Fernanda</b> y lo que más me gusta a parte de programar hehe, es conocer <b>culturas nuevas</b>, probar <b>comidas exóticas</b>, y conocer a <b>personas apasionadas</b> por viajar. La primera vez que tuve la oportunidad de viajar al extranjero lo hice a Estados Unidos, conocí a personas que me han motivado lo suficiente para continuar viajando, y creo que tú también deberías de hacerlo 🤓 Tener esta motivación de viajar , ha hecho que supere mis límites personales.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <div className="single-item">
              <div className="item-head">
                <div className="item-icon">
                  <img src={aboutIcon2} alt="appion app about icon" />
                </div>
                <h3 style={{color: "var(--textBanner)"}}>Fotografías</h3>
              </div>

              <p className="justificado" style={{color: "var(--textNormal)"}}>
              Mi papá me regalo <b>mi primera cámara</b> a los 18 y desde ese entonces no he dejado de tomar fotos, yo solía ser esa persona molesta que te tomaba fotos mientras comía haha ¡ya no lo hago! Me gusta mucho cuando las personas comparten fotos de sus mejores <b>instantes y momentos</b>, me encanta que con una sola foto puedas volver al pasado y revivir esos recuerdos. Quieres conocer las fotos que le tomé a mi ciudad da <a style={{color: "var(--textBanner)"}} rel="noopener noreferrer" href="https://almasuarez.com/puebla-y-sus-calles-pintorescas">click aquí</a>. 
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <div className="single-item">
              <div className="item-head">
                <div className="item-icon">
                  <img src={aboutIcon3} alt="appion app about icon" />
                </div>
                <h3 style={{color: "var(--textBanner)"}}>Wordpress</h3>
              </div>

              <p className="justificado" style={{color: "var(--textNormal)"}}>

              Cuando comencé a programar, supe que esto iba a <b>cambiar mi vida</b>, y vaya que lo fue, he trabajado como programadora por más de tres años y he creado, destruido,descompuesto, llorado, reído, compilado y sobre todo he aprendido que la programación es un arte que debe de ser contemplado. A todas las personas creadoras de código, quiero decirles que las admiro demasiado, gracias por crear una hermosa comunidad que se apoya continuamente.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </AboutSectionWrapper>
  );
};

export default AboutClassic;
